<template>
  <div class="layout-padding" v-if="order">
    <PageTitle
      class="my-8"
      :title="content.order + ' #' + format(id)"
      :childOne="content.account"
      :childTwo="content.orderHistory"
    />
    <div class="my-5 grid grid-cols-12 gap-x-0 md:gap-x-10">
      <div class="col-span-12 lg:col-span-8 mb-12">
        <div
          class="
            w-full
            rounded-md
            overflow-hidden
            border border-grayColor border-opacity-20
            flex flex-col
            sm:flex-row
          "
        >
          <img
            class="w-full sm:w-60 rounded object-cover"
            :src="order.package.image"
          />
          <div
            class="
              p-4
              w-full
              flex flex-col
              justify-between
              items-start
              font-semibold
            "
          >
            <div>
              <p>{{ content.packageName }}: {{ order.package.title }}</p>
              <p class="my-2">
                {{ content.GiftType }}:
                {{ order.gift ? content.itIsGift : content.forMe }}
              </p>
              <p>{{ content.orderDate }}: {{ date }}</p>
            </div>
            <div class="w-full flex items-center justify-between">
              <p>{{ content.months }}: {{ format(order.price.months) }}</p>
              <p>{{ content.price }}: {{ format(order.total) }}</p>
            </div>
          </div>
        </div>

        <!-- MONTHS TABLE -->
        <div class="w-full mt-16">
          <div
            class="
              grid grid-cols-3
              md:grid-cols-4
              border-b border-grayColor border-opacity-20
              font-bold
              pb-2
              px-3
            "
          >
            <p class="col-span-1">{{ content.month }}</p>
            <p class="col-span-1">{{ content.date }}</p>
            <p class="col-span-1 md:col-span-2">{{ content.status }}</p>
          </div>
          <div
            class="
              mt-5
              p-3
              grid grid-cols-3
              md:grid-cols-4
              rounded-md
              border border-grayColor border-opacity-20
            "
            v-for="item in order.months"
            :key="item.id"
          >
            <p class="col-span-1 font-semibold">{{ format(item.month) }}</p>
            <p class="col-span-1">
              <OrderDate :date="item.date" />
            </p>
            <p class="col-span-1 md:col-span-2">
              <OrderStatus :status="item.status" />
            </p>
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-4">
        <OrderSummery
          :status="order.status"
          :date="order.date"
          :subTotal="order.price.price"
          :delivery="order.city?.price ?? 0"
          :total="total"
          :paid="order.paid"
          @pay="payLater"
        >
        </OrderSummery>
        <OrderShipping :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderSummery from "/src/components/ui/Order/OrderSummery.vue";
import OrderShipping from "../../../components/ui/Order/OrderShipping.vue";
import OrderDate from "../../../components/ui/Order/OrderDate.vue";
import OrderStatus from "../../../components/ui/Order/OrderStatus.vue";
import { format } from "/src/assets/numberFormat";

export default {
  components: {
    OrderSummery,
    OrderShipping,
    OrderDate,
    OrderStatus,
  },
  props: ["id"],
  inject: ["content"],
  async mounted() {
    await this.$store.dispatch("subscription/mySubscriptions");

    if (!this.$store.getters["subscription/sub"](this.id)) {
      this.$router.replace("/account/orderHistory");
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    order() {
      return this.$store.getters["subscription/sub"](this.id);
    },
    date() {
      return `${this.order.date.split(",")[2]}/${
        this.order.date.split(",")[1]
      }/${this.order.date.split(",")[0]}`;
    },
    total() {
      return this.order.city
        ? parseFloat(this.order.price.price) + parseFloat(this.order.city.price)
        : this.order.price.price;
    },
  },
  methods: {
    format(number) {
      return format(number);
    },
    async payLater(method) {
      try {
        this.$store.dispatch("paylater/pay", {
          id: this.id,
          type: "subscription",
          method: method,
        });
      } catch (error) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.errorOccured,
        });
      }
    },
  },
};
</script>
